import { Injectable } from '@angular/core';
import { BaseService } from '../shared/base.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WorkOrdersBackend } from './work-orders.backend';

@Injectable()
export class WorkOrdersService extends BaseService {
  constructor(private workOrdersBackend: WorkOrdersBackend) {
    super();
  }

  getAllWorkOrders(): Observable<any> {
    return this.workOrdersBackend
      .fetchAllWorkOrders()
      .pipe(map((response) => response.body));
  }

  getAllShippingLists(): Observable<any> {
    return this.workOrdersBackend
      .fetchAllShippingLists()
      .pipe(map((response) => response.body));
  }

  syncWorkOrders(projects: string[]): Observable<any> {
    return this.workOrdersBackend
      .fetchSync(projects)
      .pipe(map((response) => response.body));
  }

  syncCheck(): Observable<any> {
    return this.workOrdersBackend
      .fetchSyncCheck()
      .pipe(map((response) => response.body));
  }

  addShippingList(shippingList: any): Observable<any> {
    return this.workOrdersBackend
      .fetchAddShippingList(shippingList)
      .pipe(map((response) => response.body));
  }

  resetDemo(): Observable<any> {
    return this.workOrdersBackend
        .resetDemo()
        .pipe(map((response) => response.body));
  }
}
