import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class WorkOrdersBackend {
  private API_ROOT = environment.apiUrl;

  private HEADERS = new HttpHeaders({
    // 'content-type': 'application/json',
    //  'enctype': 'multipart/form-data',
    // 'Auth-Token':
    //   '27563e389c45cfba3d586e9e1b785f6934a919a81aaf5619cb43b22ab58eddcb',
  });

  constructor(private http: HttpClient) {}

  fetchAllWorkOrders(): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/projects`;
    return this.http.get<any[]>(url, this.getOptions());
  }

  fetchSync(projects: string[]): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/sync`;
    return this.http.post<any[]>(url, { projects }, this.getOptions());
  }

  fetchSyncCheck(): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/sync/check`;
    return this.http.get<any[]>(url, this.getOptions());
  }

  fetchAddShippingList(shippingList: any): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/projects/add-shipping-list`;
    return this.http.post<any[]>(url, { shippingList }, this.getOptions());
  }

  fetchAllShippingLists(): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/projects/get-shipping-list`;
    return this.http.post<any[]>(url, {}, this.getOptions());
  }

  resetDemo(): Observable<HttpResponse<any>> {
    const url = `${this.API_ROOT}/projects/reset-demo`;
    return this.http.get<any[]>(url, this.getOptions());
  }

  private getOptions(): {
    headers: HttpHeaders;
    observe: 'response';
    params:
      | HttpParams
      | {
          [param: string]: string | string[];
        };
  } {
    return {
      headers: this.HEADERS,
      observe: 'response',
      params: {
        t: `${Date.now()}`,
      },
    };
  }
}
